import React from 'react';
import droll from 'droll';
import { Autocomplete, Button, Fade, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { uniqueNamesGenerator, colors, animals, NumberDictionary } from 'unique-names-generator';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const styles = theme => ({
    wrapper: {
        marginTop: "30px",
        width: "400px",
        margin: "auto",
    },
    container: {
        margin: "auto",
        fontFamily: "Scaly Sans",
        border: "1px solid black",
        borderRadius: "3px"
    },
    headerWrapper: {
        display: "flex",
        borderBottom: "2px solid #C0AD6A",
    },
    tableName: {
        fontFamily: "Mr Eaves Small Caps",
        fontSize: "32px",
        color: "#58180D",
        // borderBottom: "2px solid #C0AD6A",
        fontWeight: "800",
        margin: "auto 0px"
        // textAlign: "center"
    },
    rollSection: {
        marginTop: "10px",
        display: "flex",
        position: "relative"
    },
    rollSelector: {
        width: "70px",
        alignText: "center",
        margin: "auto",
        '& .MuiOutlinedInput-root': {
            padding: "0px"
        }
    },
    rollButton: {
        position: "absolute",
        top: "0px",
        bottom: "0px"
    },
    rollButtonRolling: {
        position: "absolute",
        top: "0px",
        bottom: "0px",
        WebkitAnimation: "linear infinite",
        WebkitAnimationName: "run",
        WebkitAnimationDuration: "1s"
    },
    rollResult: {
        margin: "auto 0px auto 40px",
        padding: "5px",
        borderBottom: "2px solid #C0AD6A",
        width: "100%",
        height: "18px"
    },
    tableDescription: {
        // fontFamily: "Scaly Sans",
        color: "black",
        margin: "10px",
        lineHeight: "20px"
    },
    tableHeader: {
        fontWeight: "800"
    }
})

class TableViewer extends React.Component{
    state = {
        activeRoll: false,
        rollResult: null,
        rows: [],
        rollOptionSelected: { label: "d20", id: 6, rowMin: 1, rowMax: 20 }
    }

    componentDidMount = () => {
        this.initTableData();
    }

    rowData = [
        {rollNumber: "1", text: "A shoe"},
        {rollNumber: "2", text: "A fish"},
        {rollNumber: "3", text: "A coin"},
        {rollNumber: "4", text: "A magical item"}
    ]

    rollOptions = [
        { label: "d4", id: 1, rowMin: 1, rowMax: 4 },
        { label: "d6", id: 2, rowMin: 1, rowMax: 6 },
        { label: "d8", id: 3, rowMin: 1, rowMax: 8 },
        { label: "d10", id: 4, rowMin: 1, rowMax: 10 },
        { label: "d12", id: 5, rowMin: 1, rowMax: 12 },
        { label: "d20", id: 6, rowMin: 1, rowMax: 20 },
        { label: "d100", id: 7, rowMin: 1, rowMax: 100 },
      ];

    renderTableRow = (row) => {
        // const premiumColor = feature.basicCheck ? "primary" : "secondary"
        return <TableRow key={row.rollNumber}>
            <TableCell align="center" style={{position: "relative"}}>
                {row.rollNumber}
            </TableCell>
            <TableCell align="center">
                {row.text}
            </TableCell>
        </TableRow>
    }

    renderResult = () => {
        if(this.state.rollResult){
            const {rollNumber, text} = this.state.rollResult;
            return <div className={this.props.classes.rollResult}>
                <Fade in={Boolean(this.state.rollResult)}>
                    <div>
                        <b>{rollNumber} - </b>{text}
                    </div>
                </Fade>
            </div>
        }else{
            return <div className={this.props.classes.rollResult}>
                {this.state.activeRoll ? "" : "⬅ Click to roll!"}
            </div>
        }
    }

    roll = () => {
        // alert(this.state.rollOptionSelected.label);
        const rollNumber = Number(droll.roll(this.state.rollOptionSelected.label));
        const rollResult = this.state.rows.find(r => r.rollNumber === rollNumber);
        this.setState({activeRoll: true, rollResult: null}, () => {
            setTimeout(() => this.setState({rollResult, activeRoll: false}), 500)
        });
    }

    randomName = () => {
        // const number = NumberDictionary.generate({ min: 100, max: 999 });
        return uniqueNamesGenerator({ 
            dictionaries: [colors, animals],
            separator:" ",
            style: "capital" 
        });
    }

    initTableData = () => {
        const option = this.state.rollOptionSelected;
        const rows = [];
        for(let i=option.rowMin; i <= option.rowMax; i++){
            rows.push({rollNumber: i, text: this.randomName()});
        }
        this.setState({rows});
    }

    render() {
        return <div className={this.props.classes.wrapper}>
            <div className={this.props.classes.headerWrapper}>
                <div className={this.props.classes.tableName}>
                    Strange Animal Generator
                </div>
                {/* <div className={this.props.classes.rollSelector}>
                    d20
                </div> */}
            </div>
            <div className={this.props.classes.rollSection}>
                <IconButton
                    classes={{root: this.state.activeRoll 
                        ? this.props.classes.rollButtonRolling 
                        : `fade-in ${this.props.classes.rollButton}`
                    }}
                    size="medium"
                    onClick={this.roll}
                >
                    {/* <FontAwesomeIcon color={"#fff"} className="fa-inverse" icon={faCircle}/> */}
                    {/* <FontAwesomeIcon className="fa-fast-spin" color="#000" icon="dice-d20"/> */}
                    <FontAwesomeIcon color="#000" icon="dice-d20"
                        className={this.state.activeRoll ? "fa-fast-spin" : ""}
                    />
                </IconButton>
                {this.renderResult()}
            </div>
            <div className={this.props.classes.tableDescription}>
                You have finally reached your destination - the wild jungles of Shla'anzur. There are many strange creatures in this area. Roll to see what you encounter first!
            </div>
            <div className={this.props.classes.container}>

                <TableContainer className="page">
                    <Table aria-label="simple table" size="small">
                        <TableHead className={this.props.classes.tableHeader}>
                            <TableRow key={1}>
                                <TableCell align="center">
                                <Autocomplete
                                    freeSolo
                                    options={this.rollOptions}
                                    className={this.props.classes.rollSelector}
                                    renderInput={(params) => {
                                        params.inputProps.sx =  { ...params.inputProps.sx,
                                            fontWeight: "800",
                                            textAlign: "center",
                                        };
                                        return <TextField  variant="outlined" focused {...params}/> }
                                    }
                                    disableClearable
                                    blurOnSelect
                                    value={this.state.rollOptionSelected}
                                    onChange={(e, value) => {
                                        this.setState({rollOptionSelected: value}, this.initTableData);
                                    }}
                                    
                                />
                                </TableCell>
                                <TableCell align="center">Result</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.rows.map(this.renderTableRow)}
                            {/* {this.rowData.map(this.createTableRow)} */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        // userId: state.user._id,
        // viewWidth: state.reactive.width
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    //   closeActiveNote: noteId => dispatch(closeActiveNote(noteId))
    }
};
  
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TableViewer)));