import * as axios from 'axios';
import { firebaseAuth } from './firebaseAuth';
import { sleep } from '../lib/util/general';

export const serverBaseUrl = "https://dragonroll-1.uc.r.appspot.com";
// export const serverBaseUrl = "http://192.168.0.111:8080";

// !TODO: Fix sleeping habits :)
// Currently sleeps in wait for user auth. should do better.
const getAuthToken = async () => {
    if(!firebaseAuth || !firebaseAuth.currentUser){
        await sleep(100);
        return getAuthToken();
    }
    return firebaseAuth.currentUser.getIdToken(true)
        .catch(async err => {
            console.log(err.message);
            await sleep(100);
            return getAuthToken();
        });
}

const addAuthToUrl = async rawUrl => {
    const url = new URL(rawUrl);
    const token = await getAuthToken();
    url.searchParams.set("authToken", token);
    return url.toString();
}

const get = async (url, requiresAuth = true) => {
    if(requiresAuth) url = await addAuthToUrl(url);
    const result = await axios.get(url);
    return result.data;
}

const post = async (url, body, requiresAuth = true) => {
    if(requiresAuth) url = await addAuthToUrl(url);
    const result = await axios.post(url, body);
    return result.data;
}

const del = async (url, requiresAuth = true) => {
    if(requiresAuth) url = await addAuthToUrl(url);
    const result = await axios.delete(url);
    return result.data;
}

export const user = {
    create: (userId) => { //No auth; creating users is done by non-users!
        return post(`${serverBaseUrl}/user/create`, { _id: userId }, false);
    },
    get: (userId) => {
        return get(`${serverBaseUrl}/user/${userId}`);
    },
    checkUsage: (userId, sizeDelta) => {
        return get(`${serverBaseUrl}/user/${userId}/checkUsage?sizeDelta=${sizeDelta}`)
            .then(res => res.withinLimits);
    },
    update: (userId, updates) => {
        return post(`${serverBaseUrl}/user/update/${userId}`, updates);
    },
    delete: (userId, feedback) => {
        return post(`${serverBaseUrl}/user/delete/${userId}`, {feedback});
    },
    redirectTostripeCustomerPortal: (customerId) => {
        return post(`${serverBaseUrl}/user/stripeCustomerPortal/${customerId}`)
            .then(res => res.stripeUrl);
    }
}

export const gen = {
    create: (contentType) => {
        return post(`${serverBaseUrl}/gen/create?&contentType=${contentType}`, {})
    }
}
// export const share = {
//     get: (shareId) => {
//         return get(`${serverBaseUrl}/shareId/${shareId}`)
//     },
// }