import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { userReducer } from './userReducer';
import { visibilityReducer } from './visibilityReducer';
import { locationReducer } from './locationReducer';
import { reactiveReducer } from './reactiveReducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    visibility: visibilityReducer,
    user: userReducer,
    location: locationReducer,
    reactive: reactiveReducer
});