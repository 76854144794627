import { LOCATION_CHANGE } from 'connected-react-router'

export const defaultState = {
    viewPageId: null,
    viewNoteId: null,
    viewBookId: null,
    viewSearch: null,
    viewShareId: null,
    listViewType: 'all'
};

export const locationReducer = (state = defaultState, action) => {
    switch (action.type) {

        case LOCATION_CHANGE: {
            if(action.payload && action.payload.location){
                const location = action.payload.location;
                
                const params = new URLSearchParams(location.search);
                let stateChanges = {};

                //Foreground
                stateChanges.viewPageId = params.get("page") || null; //todo: refactor into fgPage (foreground)
                stateChanges.viewNoteId = params.get("note") || null;

                //Background
                stateChanges.listViewType = params.get("bgPage") || defaultState.listViewType; //default to all notes
                stateChanges.viewBookId = params.get("book") || null;
                stateChanges.viewSearch = params.get("search") || null;

                stateChanges.viewShareId = params.get("shareId") || null;

                return Object.assign({}, state, stateChanges);
            }
            return state; //Default
        }

        default:
            return state;
    }
}
