import React from 'react';
import MainComponent from './MainComponent';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './redux/store';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

// // import { DndProvider } from 'react-dnd'
// // import HTML5Backend from 'react-dnd-html5-backend';
// // import {TouchBackend} from 'react-dnd-touch-backend';
// // import MultiBackend, { TouchTransition, MouseTransition } from 'react-dnd-multi-backend';

import theme from './theme';

// const HTML5toTouch = {
//     backends: [
//       {
//         id: 'html5',
//         backend: HTML5Backend,
//         transition: MouseTransition,
//       },
//       {
//         id: 'touch',
//         backend: TouchBackend,
//         options: {enableMouseEvents: true, delayTouchStart: 300}, // Note that you can call your backends with options
//         preview: true,
//         transition: TouchTransition,
//       },
//     ],
//   };

class App extends React.Component {
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme} /* MaterialUI */>
          <Provider store={store} /* Redux */>
            <ConnectedRouter history={history} /* React Router */>
              {/* <DndProvider backend={MultiBackend} options={HTML5toTouch} > */}
                <Switch>
                  <Route exact path="/" children={<MainComponent {...this.props} />} />
                  {/* <Route path="/note/:id" children={<MainComponent {...this.props} />} /> */}
                </Switch>
              {/* </DndProvider> */}
            </ConnectedRouter>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;