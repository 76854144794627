import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { withStyles } from '@mui/styles';


const styles = theme => ({
    searchWrapper: {
      "backgroundColor": "rgb(0,0,0,.5)",
      "width": "100%",
      "box-sizing": "border-box",
      "maxWidth": "350px",
      "display": "inline-block",
      "margin": "auto",
    //   "padding": "2px",
    //   "border": "1px solid black",
      "borderRadius": "10px"
    },
    fullWidthSearchBarWrapper: {
        // "backgroundColor": "white",
        "width": "100%",
        "box-sizing": "border-box",
        "display": "inline-block",
        "margin": "auto",
        "padding": "2px"
    },
    searchBar: {
      "display": "inline-flex",
      "width": "100%"
    },
    searchText: {

        "padding": "6px 8px 8px 16px",
    //   "padding": "4px 8px 4px 8px",
      "marginLeft": "5px",
    //   "borderRight": "1px solid black",
    },
    searchButton: {
      "background": "rgb(0,0,0,0)",
      "marginLeft": "2px",
      "padding": "0",
      "border": "none",
      "borderRadius": "10px",
      "font": "inherit",
      "fontSize": "0",
      "lineHeight": "normal",
      "outline": "none",
      "transition": "0.3s",
      "&:hover": {
        "background": "rgb(0,0,0,.2)"
      }
    },
    clearSearchButton: {
      "background": "#505459",
      "marginLeft": "2px",
      "padding": "0",
      "border": "none",
      "borderRadius": "30px",
      "font": "inherit",
      "fontSize": "0",
      "lineHeight": "normal",
      "outline": "none",
      "transition": "0.3s",
      "&:hover": {
        "background": "#676c73"
      }
    },
    searchIcon: {
      "margin": "10px",
      "width": "20px",
      "color": "rgba(255,255,255,.6)",
    //   color: theme.palette.secondary.main // interesting idea
    }
})

class SearchBar extends React.Component {

    state = {
        searchText: '',
        searchIconVis: true
    }

    componentDidMount(){
        //Initial population in case search already in URL
        if(this.state.searchText === '' && this.props.viewSearch !== null){
            this.setState({searchText: this.props.viewSearch, searchIconVis: false});
        }
    }

    componentDidUpdate(prevProps){
        //Reset search if viewing a book now
        if(!this.props.viewSearch && this.props.viewSearch !== prevProps.viewSearch){
            this.setState({searchText: '', searchIconVis: true})
        }
    }

    search = e => {
        e.preventDefault();
        if(this.props.onSubmit) this.props.onSubmit();
        this.setState({searchIconVis: false});
        this.props.history.push(`?bgPage=search&search=${encodeURIComponent(this.state.searchText)}`);
    }

    reset = e => {
        if(this.props.onSubmit) this.props.onSubmit();
        this.setState({searchIconVis: true, searchText: ''});
        this.props.history.push('/');
    }

    render() {
        return (
            <div className={Boolean(this.props.fullWidth) ? this.props.classes.fullWidthSearchBarWrapper : this.props.classes.searchWrapper}>
                <form className={this.props.classes.searchBar} onSubmit={this.search} onReset={this.reset}>
                <input
                    autoFocus={Boolean(this.props.autoFocus)}
                    className={this.props.classes.searchText}
                    type="text"
                    value={this.state.searchText}
                    onChange={event => this.setState({ searchText: event.target.value })}
                    placeholder="Find Tables..."
                />
                <button className={this.props.classes.searchButton} type="submit" hidden={!this.state.searchIconVis}>
                    <FontAwesomeIcon class={this.props.classes.searchIcon} icon={faSearch} />
                </button>
                <button className={this.props.classes.clearSearchButton} type="reset" hidden={this.state.searchIconVis}>
                    <FontAwesomeIcon class={this.props.classes.searchIcon} icon={faTimesCircle}/>
                </button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        viewSearch: state.location.viewSearch
        // viewNoteId: state.location.viewNoteId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    //   closeActiveNote: noteId => dispatch(closeActiveNote(noteId))
    }
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SearchBar)));


// import React from 'react';
// import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

// class SearchBar extends React.Component {

//     state = {
//         searchText: '',
//         searchIconVis: true
//     }

//     componentDidMount(){
//         //Initial population in case search already in URL
//         if(this.state.searchText === '' && this.props.viewSearch !== null){
//             this.setState({searchText: this.props.viewSearch, searchIconVis: false});
//         }
//     }

//     componentDidUpdate(prevProps){
//         //Reset search if viewing a book now
//         if(!this.props.viewSearch && this.props.viewSearch !== prevProps.viewSearch){
//             this.setState({searchText: '', searchIconVis: true})
//         }
//     }

//     search = e => {
//         e.preventDefault();
//         this.setState({searchIconVis: false});
//         this.props.history.push(`?search=${encodeURIComponent(this.state.searchText)}`);
//     }

//     reset = e => {
//         this.setState({searchIconVis: true, searchText: ''});
//         this.props.history.push('/');
//     }

//     render() {
//         const p = this.props;
//         return (
//             <div className="searchWrapper">
//                 <form className="searchBar" onSubmit={this.search} onReset={this.reset}>
//                 <input
//                     className="searchText"
//                     type="text"
//                     value={this.state.searchText}
//                     onChange={event => this.setState({ searchText: event.target.value })}
//                     placeholder="Search Notes..."
//                 />
//                 <button className="searchButton" type="submit" hidden={!this.state.searchIconVis}>
//                     <FontAwesomeIcon class="searchIcon" icon={faSearch} />
//                 </button>
//                 <button className="clearSearchButton" type="reset" hidden={this.state.searchIconVis}>
//                     <FontAwesomeIcon class="searchIcon" icon={faTimesCircle}/>
//                 </button>
//                 </form>
//             </div>
//         );
//     }
// }

// const mapStateToProps = (state /*, ownProps*/) => {
//     return {
//         viewSearch: state.location.viewSearch
//         // viewNoteId: state.location.viewNoteId
//     }
// }

// const mapDispatchToProps = dispatch => {
//     return {
//     //   closeActiveNote: noteId => dispatch(closeActiveNote(noteId))
//     }
// };

// export default withRouter(connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(SearchBar));