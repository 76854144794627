// import { serverBaseUrl } from "../../services/serverComm";

export const SIDEBAR_WIDTH = 200;
export const BASE_URL = `http://localhost:3000`;
// export const BASE_URL = `https://www.octonote.com/`;
export const AUTOSAVE_CADENCE = 1 * 30 * 1000; // 30 seconds
// export const FILE_POND_SERVER_SETTINGS = {
//     process: null,
//     revert: null,
//     url: serverBaseUrl,
//     load: '/files/load?url=',
//     fetch: '/files/fetch?url='
// };

// export const ANON_USER_MAX_DATA_SIZE = 10 * Math.pow(1024, 2); //10MB
// export const BASIC_USER_MAX_DATA_SIZE = 100 * Math.pow(1024, 2); //100MB
// export const ANON_USER_MAX_NOTE_COUNT = 10;
// export const BASIC_USER_MAX_NOTE_COUNT = 117;
// export const PREMIUM_USER_MAX_DATA_SIZE = 100 * Math.pow(1024, 3); //100 GB

export const ANNUAL_PRICE_ID = "price_1IGdiZGTGsTB6vri3cGpgcAv";
export const MONTHLY_PRICE_ID = "price_1IGdiZGTGsTB6vriu1JsRKkR";

export const TERMS_URL = "https://app.termly.io/document/terms-of-use-for-saas/b7ace1c6-2549-4842-aa95-25b6d99ec4fd";
export const PRIVACY_URL = "https://app.termly.io/document/privacy-policy/d9f509fc-bfe1-41b0-8135-468123de79cc";