import React from 'react';
import { withRouter } from 'react-router-dom';
import NavBar from './components/navBar/navBar';

import { connect } from 'react-redux'
import { userLogin, closeSignInDialog } from './redux/actions'
import { withStyles } from '@mui/styles';
import { firebaseAuth } from './services/firebaseAuth';
import { gen, user } from './services/serverComm';
import SignInDialog from './users/signInDialog';
import PurchaseDialog from './users/purchaseDialog';
import DeleteAccountDialog from './users/deleteAccountDialog';
import PageViewer from './pages/pageViewer';
import TableViewer from './tables/tableViewer';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import ContentViewer from './components/contentViewer/contentViewer';
import CreationSection from './components/creationSection';

library.add(faDiceD20);
library.add(faCopy);



const styles = theme => ({
    mainSection: {
        fontSize: "72px",
        fontWeight: "200",
        textAlign: "center",
        paddingTop: "100px",
        fontFamily: "Nodesto Caps Condensed",
        color: "#58180d",
    },
    mainWrapper: {
        // display: "flex",
        // flexDirection: "column"
    }
})

class MainComponent extends React.Component {
    contentViewerRef = React.createRef();

    state = {
        loading: true,
        user: {}
    };

    componentDidMount() {
        console.log('🌞');

        firebaseAuth.onAuthStateChanged(
            async (firebaseUser) => {
                
                this.setState({loading: true});
                // console.log(firebaseUser?.providerData[0]?.providerId);
                //user.isAnonymous
                if(!firebaseUser){ //Sign in anonymously if no user is signed in
                    firebaseAuth.signInAnonymously();
                }else{
                    // await firebaseUser.getIdToken(true);
                    // const decodedToken = await firebaseUser.getIdTokenResult();
                    // console.log(decodedToken);
                    let user = await this.getUser(firebaseUser.uid);
                    if(!user){ //Create Anon in our system
                        user = await this.createUser(firebaseUser.uid);
                    }
                    console.log(user);
                    user.providerId = firebaseUser?.providerData[0]?.providerId; //TODO: Make this a legit user prop
                    this.props.userLogin(user);

                    
                    // Init the UI with user's data


                    //firebase auth uses uiConfig to invoke callbacks on sign-in which lacks redux
                    // so make sure sign in dialog is closed
                    if(this.props.signInDialogOpen){
                        this.props.closeSignInDialog();
                    }
                }
            });
    }

    getUser = userId => {
        return user.get(userId);
    }

    createUser = (userId, userEmail, imageUrl) => {
        return user.create(userId);
    }

    generateContent = async (contentType) => {
        this.contentViewerRef.current.setLoading();
        let genResult = await gen.create(contentType);
        //TODO: ERROR HANDLING FOR MISSING DATA
        // this.setState({
        //     loading: false,
        //     contentLoadingMessage: "",
        //     genBarOpen: true
        // });
        
        // const content = genResult.content
            // .replace(/\n/g, "<br/>")

        this.contentViewerRef.current.setContent(genResult);
            // .replace("<br/><br/>", ""); //Only need to replace the first instance since GPT returns that for some reason
        // this.setTitle(genResult.title);
        // this.contentRef.current.setContent(content);
    }

    render() {
        return (
            <div className={this.props.classes.mainWrapper}>
                <NavBar/>
                {/* <div className={this.props.classes.mainSection}>
                    {`Welcome to Dragonroll`}
                </div> */}
                <ContentViewer
                    wrappedComponentRef={this.contentViewerRef}
                />
                <CreationSection
                    generateContent={this.generateContent}
                />
                {/* <TableViewer/> */}
                {/* <Modal> */}
                <PageViewer/>
                {/* <ActionDialogs> */}
                <SignInDialog/>
                <PurchaseDialog/>
                <DeleteAccountDialog/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        userLogin: userId => dispatch(userLogin(userId)),
        closeSignInDialog: () => dispatch(closeSignInDialog())
    }
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        signInDialogOpen: state.visibility.signInDialogOpen
    }
}

export default 
withRouter(
connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(MainComponent)));