import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { createMuiTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogExitButton from '../lib/util/dialogExitButton'

import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { closeDeleteAccountDialog, initUserDeletion } from '../redux/actions';
import { user } from '../services/serverComm';



const customTheme = createMuiTheme(adaptV4Theme({
    palette: {
        primary: {main: "#cc0029"},
    }
}));

const styles = theme => ({
    root: {
        zIndex: 5000
    },
    feedbackForm: {
        marginTop: "15px"
    },
    dialogPaper: {  
        // maxWidth: "100%",
        maxHeight: "100%",
        width: "100%",
        margin: "0"
    },
})

class DeleteAccountDialog extends React.Component{
    state = {
        feedback: ""
    }

    deleteAccount = () => {
        user.delete(this.props.userId, this.state.feedback);
        this.props.initUserDeletion();
        this.props.closeDeleteAccountDialog();
    }

    deleteAccountText = () => {
        return <span>Deleting your account will <b>permanently</b> delete all of your notes, books, images, etc. 
            The Octonote team will begin the deletion process shortly after your request is submitted.
            If you don't mind, please leave your feedback below so that we may improve:
             </span>
    }

    updateFeedback = (e) => {
        this.setState({feedback: e.target.value})
    }

    render(){
        return (
            <Dialog
                classes={{
                    root: this.props.classes.root,
                    paper: this.props.classes.dialogPaper
                }}
                open={Boolean(this.props.deleteAccountDialogOpen)}
                onClose={this.props.closeDeleteAccountDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                fullWidth
            >
                <DialogExitButton
                    onClick={this.props.closeDeleteAccountDialog}
                />
                <DialogTitle id="alert-dialog-title">
                    Just a moment!!
                </DialogTitle>
                <DialogContent>
                    {this.deleteAccountText()}
                    <form className={this.props.classes.feedbackForm}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Feedback"
                            multiline
                            rows={2}
                            fullWidth
                            autoFocus
                            variant="outlined"
                            value={this.state.feedback}
                            onChange={this.updateFeedback}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={this.props.closeDeleteAccountDialog}
                    >
                        Cancel
                    </Button>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={customTheme}>
                            <Button 
                                onClick={this.deleteAccount}
                                color="primary"
                                variant="contained"
                                startIcon={<FontAwesomeIcon icon={faFrown}/>}
                            >
                                Delete
                            </Button>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </DialogActions>
            </Dialog>
        );
    }
}



const mapStateToProps = (state /*, ownProps*/) => {
    return {
        userId: state.user._id,
        // deleteNote: state.noteActions.deleteNote,
        // viewNoteId: state.location.viewNoteId
        deleteAccountDialogOpen: state.visibility.deleteAccountDialogOpen
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeDeleteAccountDialog : () => dispatch(closeDeleteAccountDialog()),
        initUserDeletion : () => dispatch(initUserDeletion()),
        // removeNoteFromList : (noteId) => dispatch(removeNoteFromList(noteId)),
        // adjustUserUsage : (noteCount, dataSize) => dispatch(adjustUserUsage(noteCount, dataSize))
    }
};

export default
withRouter(
connect(
    mapStateToProps,
    mapDispatchToProps
)(
withStyles(styles)
(DeleteAccountDialog)));