// Import FirebaseAuth and firebase.
import React from 'react';
import firebase from 'firebase';

import { connect } from 'react-redux'
import { ClickAwayListener } from '@mui/material';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import ErrorPopper from '../lib/util/errorPopper';
import DialogExitButton from '../lib/util/dialogExitButton';

import { firebaseAuthUiConfig, firebaseAuth, signIn } from '../services/firebaseAuth';

// import 'firebaseui/dist/firebaseui.css'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { closeSignInDialog } from '../redux/actions';
import { BASE_URL, PRIVACY_URL, TERMS_URL } from '../lib/util/constants';

const styles = theme => ({
    dialogPaper: {  
        // maxWidth: "100%",
        maxHeight: "100%",
        width: "100%",
        margin: "0"
    },
    root: {
        padding: "10px",
        // width: "300px",
    },
    title: {
        fontSize: "26px",
        fontWeight: "300",
        textAlign: "center",
        borderBottom: "thin solid #d7dbdb",
        margin: "0",
        paddingBottom: "10px"
    },
    registerSwitchWrapper: {
        textAlign: "center",
        padding: "0px",
        marginTop: "0px",
        marginBottom: theme.spacing(1)
    },
    signInWrapper: {
        display: "block",
        textAlign: "center",
        marginRight: "auto",
        marginLeft: "auto",
        padding: "0px"
    },
    signInButtonInner: {
        textTransform: "none",
        color: "white",
        fontSize: "16px",
        whiteSpace: "nowrap",
        border: "1px solid white",
        display: "block",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    typography: {
        padding: theme.spacing(2),
    },
    terms: {
        color: "darkgray",
        fontSize: "12px",
        textAlign: "center"
    },
    termsUrl: {
        color: "darkgray"
    },
})

class SignInDialog extends React.Component {
    signInButtonRef = React.createRef();
    state = {
        registerSwitchOn: false,
        emailInput: "",
        passwordInput: "",
        errorPopperAnchorEl: null,
        popperErrorMessage: ""
    };

    componentDidUpdate(prevProps){
        if(this.props.signInRegisterSwitchOn && !this.state.registerSwitchOn
            && this.props.signInRegisterSwitchOn != prevProps.signInRegisterSwitchOn //Only flip 1 time
        ){
            this.toggleRegisterSwitch();
        }
    }

    resetErrorMessage = () => {
        this.setState({popperErrorMessage: null});
    }

    toggleRegisterSwitch = () => {
        this.setState({registerSwitchOn: !this.state.registerSwitchOn});
    }

    registerUser = e => {
        //Upgrade anon to registered user
        const credential = firebase.auth.EmailAuthProvider.credential(this.state.emailInput, this.state.passwordInput);
        return firebaseAuth.currentUser.linkWithCredential(credential)
            .then(() => signIn(credential))
            .then(() => firebaseAuth.currentUser.sendEmailVerification({
                url: BASE_URL
            }))
            .then(() => this.props.closeSignInDialog())
            .catch(err => {
                this.setState({errorPopperAnchorEl: e.currentTarget, popperErrorMessage: err.message});
            })
    }
        
    signInOrRegister = e => {
        console.log('SignIn / Reg');
        e.preventDefault();

        if(this.state.registerSwitchOn){
            return this.registerUser(e) 
        }else{
            return firebaseAuth.signInWithEmailAndPassword(this.state.emailInput, this.state.passwordInput)
                .then(() => this.props.closeSignInDialog())
                .catch(err => {
                    this.setState({errorPopperAnchorEl: e.currentTarget, popperErrorMessage: err.message});
                })
        }
    }

    showTerms = () => {
        
        return <div className={this.props.classes.terms}>
            By registering, you agree to our <br/>
            <a className={this.props.classes.termsUrl} href={TERMS_URL} target="_blank">Terms of Use</a> and <a className={this.props.classes.termsUrl} href={PRIVACY_URL} target="_blank">Privacy Policy</a>
        </div>
    }

    render() {      
        return (
            <Dialog
                    classes={{paper: this.props.classes.dialogPaper}}
                    open={this.props.signInDialogOpen}
                    onClose={this.props.closeSignInDialog}
                >
                    <DialogExitButton onClick={this.props.closeSignInDialog}/>
                    <div className={this.props.classes.root}>
                        <h2 className={this.props.classes.title}>Sign In or Register</h2>
                        <Grid className={this.props.classes.registerSwitchWrapper} component="label" container alignItems="center" justifyContent="center" spacing={1}>
                            <Grid item>Sign In</Grid>
                            <Grid item>
                                <Switch disableRipple checked={this.state.registerSwitchOn} onChange={this.toggleRegisterSwitch} name="registerSwitch" />
                            </Grid>
                            <Grid item>Register</Grid>
                        </Grid>
                        <form 
                            className={this.props.classes.signInWrapper}
                            autoComplete="off"
                            onSubmit={this.signInOrRegister}
                        >
                            <TextField 
                                value={this.state.emailInput}
                                onChange={e => this.setState({emailInput: e.target.value, popperErrorMessage: null})}
                                margin="dense"
                                variant="outlined"
                                id="email"
                                label="Email"
                                autoFocus
                                type="email"
                                InputLabelProps={{
                                    style: {color: "#d7dbdb"}
                                }}
                            />
                            <TextField
                                value={this.state.passwordInput}
                                onChange={e => this.setState({passwordInput: e.target.value, popperErrorMessage: null})}
                                margin="dense"
                                variant="outlined"
                                id="password"
                                label="Password"
                                type="password"
                                InputLabelProps={{
                                    style: {color: "#d7dbdb"}
                                }}
                            />
                            <Button
                                ref={this.signInButtonRef}
                                variant="contained"
                                color={this.state.registerSwitchOn ? "secondary" : "primary"}
                                className={this.props.classes.signInButtonInner}
                                // startIcon={<FontAwesomeIcon className='fa-sm' icon={faUser}/> }
                                type="submit"
                            >
                                {this.state.registerSwitchOn ? "Register" : "Sign In"}
                            </Button>
                        </form>
                        <ErrorPopper
                            anchorEl={this.signInButtonRef.current}
                            errorMessage={this.state.popperErrorMessage}
                            resetErrorMessage={this.resetErrorMessage}
                        />
                        {/* <Divider/> */}
                        <Typography style={{textAlign: "center"}}><i>OR</i></Typography>
                        <StyledFirebaseAuth 
                            uiConfig={firebaseAuthUiConfig}
                            firebaseAuth={firebaseAuth} 
                        />
                        {this.state.registerSwitchOn
                            ? this.showTerms()
                            : null
                        }
                    </div>
                </Dialog>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeSignInDialog: () => dispatch(closeSignInDialog()),
    }
};

const mapStateToProps = (state) => {
    return {
        signInDialogOpen: state.visibility.signInDialogOpen,
        signInRegisterSwitchOn: state.visibility.signInRegisterSwitchOn
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(SignInDialog));