import { Types } from './actions';

export const defaultState = {};

export const reactiveReducer = (state = defaultState, action) => {
    switch (action.type) {

        case Types.CHANGE_VIEW_WIDTH: {
            return action.width ? Object.assign({}, state, { width: action.width }) : state;
        }

        default: {
            return state;
        }
    }
}
