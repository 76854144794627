import * as axios from 'axios';
import firebase from 'firebase';
import store from '../redux/store';
import { userLogin, newActiveBook } from '../redux/actions'
import { user as userServer } from './serverComm';

const firebaseConfig = {
    apiKey: "AIzaSyBnYqfvNSsZge754SJREYZbm8NQWS8ExxE",
    authDomain: "dragonroll-1.firebaseapp.com",
    projectId: "dragonroll-1",
    storageBucket: "dragonroll-1.appspot.com",
    messagingSenderId: "981869256434",
    appId: "1:981869256434:web:d1358f791d1a8c34afba6b",
    measurementId: "G-3DWBSB22X0"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firebaseAuthUiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    autoUpgradeAnonymousUsers: true,
    signInOptions: [
        // {
        //     // Google provider must be enabled in Firebase Console to support one-tap
        //     // sign-up.
        //     provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //     // Required to enable ID token credentials for this provider.
        //     // This can be obtained from the Credentials page of the Google APIs
        //     // console. Use the same OAuth client ID used for the Google provider
        //     // configured with GCIP or Firebase Auth.
        //     clientId: '418783652722-ucc1dr9pf7cfr4hombe0umdnnj7qvv1u.apps.googleusercontent.com'
        // },
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    // credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: (authResult) => {
            console.log(authResult);
            // Process result. This will not trigger on merge conflicts.
            // On success redirect to signInSuccessUrl.
            signIn(authResult.credential);
            return false;
        },
        signInFailure: error => {
            if (error.code != 'firebaseui/anonymous-upgrade-merge-conflict') {
                return Promise.resolve();
            }else{
                // TODO: Implement Merge Conflict handling
                // Problematic case right now:
                // 1. User Signs up with Google
                // 2. User logs out
                // 3. User is auto-logged in as Anon
                // 4. User creates a note
                // 5. User logs in with google creds again....
                // 6. Current logic will make notes from step 4 lost forever
                // See https://github.com/firebase/firebaseui-web#configuration
                signIn(error.credential);
            }
        } 
    }
};

//TODO: Figure out how to pull picture
export const signIn = credential => {
    return firebaseAuth.signInWithCredential(credential)
        .then(async usercrd => {
            //TODO: Should only update when user is upgrade from Anon to full; not every time.
            // await updateUser(usercrd.user);
            await userServer.update(usercrd.user.uid, {
                //TODO: imageUrl: photoURL, which is currently broken on anon upgrade logins
                email: usercrd.user.email
            });
            const user = await userServer.get(usercrd.user.uid);

            //Non-connect redux execution! 
            // ... but is it needed? userLogin handled in MainComponent
            store.dispatch(userLogin(user));
        })
}
//TODO: Consider https://github.com/firebase/firebase-js-sdk/issues/462
export const firebaseAuth = firebase.auth();
export const firestore = firebaseApp.firestore();
export const analytics = firebase.analytics();