import * as React from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';

import { firestore } from '../services/firebaseAuth';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Check from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';

// import OctonoteAnimation from '../lib/octonoteAnimation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import { closePurchaseDialog } from '../redux/actions';
import { SMALL_BUTTON_STYLES } from '../theme';
                    
const styles = theme => ({
    dialogPaper: {  
        // maxWidth: "100%",
        maxHeight: "100%",
        width: "100%",
        margin: "0"
    },
    root: {
        padding: "10px",
        // width: "300px",
    },
    closeButtonRoot: {
        [theme.breakpoints.down('md')]: SMALL_BUTTON_STYLES,
        position: "absolute",
        top: "40%", //For some reason looks nicer
        transform: "translateY(-50%)",
        left: "5px",
        zIndex: "3",
    },
    header: {
        position: "relative"
    },
    title: {
        fontSize: "26px",
        fontWeight: "300",
        textAlign: "center",
        borderBottom: "thin solid #d7dbdb",
        margin: "0px",
        paddingBottom: "10px"
    },
    paymentSwitchWrapper: {
        textAlign: "center",
        padding: "0px",
        marginTop: "0px",
        marginBottom: theme.spacing(1)
    },
    typography: {
        padding: theme.spacing(2),
    },
    priceContainer: {
        background: theme.palette.primary.main,
        color: "white",
        display: "flex",
        textAlign: "center",
        verticalAlign: "top",
        padding: "5px 10px 10px 5px",
        fontSize: "24px",
        fontWeight: "300",
        // paddingBottom: "30px",
        alignItems: "center",
        justifyContent: "center",
        width: "max-content",
        margin: "auto"
    },
    priceDollarSign: {

    },
    priceDollarAmount: {
        fontSize: "62px",
        lineHeight: "1"
    },
    priceCentsAmount: {
        marginTop: "-20px"
    },
    pricePerMo: {
        fontSize: "14px",
        marginTop: "20px",
        // marginRight: "-20px"
    },
    billedAnnuallyText: {
        height: "15px", //Keeps height consistent when hiding block
        fontSize: "11px",
        textAlign: "center",
        color: "gray",
        marginTop: "3px",
        marginBottom: "5px"
    },
    purchaseButton: {
        color: "white",
        border: "1px solid white",
        textTransform: "none",
        fontSize: "18px",
        fontWeight: "400",
        marginTop: "15px",
        padding: "5px",
        width: "100%"
        // margin: theme.spacing(1),
    },
    loadingCheckoutMessage: {
        position: "absolute",
        top: "100px",
        width: "100%",
        left: "50%",
        textAlign: "center",
        fontStyle: "italic",
        transform: "translateX(-50%)"
    }
})

class PurchaseDialog extends React.Component {
    state = {
        loading: false,
        annualSwitchOn: true,
    };

    toggleAnnualSwitch = () => {
        this.setState({annualSwitchOn: !this.state.annualSwitchOn});
    }


    createTableRow(featureText) {
        return <TableRow key={featureText}>
            <TableCell align="right"><Check color="primary"/></TableCell>
            <TableCell align="center">{featureText}</TableCell>
        </TableRow>
    }
      
    onClickPurchase = async () => {
        this.setState({loading: true});
        const docRef = await firestore
            .collection('customers')
            .doc(this.props.user._id)
            .collection('checkout_sessions')
            .add({
                price: this.state.annualSwitchOn ? 'price_1IGdiZGTGsTB6vri3cGpgcAv' : 'price_1IGdiZGTGsTB6vriu1JsRKkR',
                success_url: window.location.origin,
                cancel_url: window.location.origin,
            });
            // Wait for the CheckoutSession to get attached by the extension
            docRef.onSnapshot(async (snap) => {
                const { error, sessionId } = snap.data();
                if (error) {
                    // Show an error to your customer and 
                    // inspect your Cloud Function logs in the Firebase console.
                    alert(`An error occured: ${error.message}`);
                }
                if (sessionId) {
                    // We have a session, let's redirect to Checkout
                    // Init Stripe
                    const stripe = await loadStripe("pk_live_51HsUGNGTGsTB6vripr9Y6tYmr6llhvfmlaWipIobG4CazoEXNgJgZOmUDddRlUSJuq93aqnbaVaFByoo5veDmJHB00JkOyqzNh");
                    stripe.redirectToCheckout({ sessionId });
                }
            });
    }

    features = [
        
        // <div style={{color: "#0074D9", background: "whitesmoke"}}>Cross-note Linking</div>,
        "Cross-note Linking",
        "Image Annotations",
        "Note & Book Sharing",
        "Rich Text Formatting",
        "Flexible Organization", //Playlist-like, many-to-many, one note many books, advanced, nested, bookshelf
        // "Advanced Organization",
        <><b>Unlimited</b> notes<br/><b>100GB</b> data</>
    ];
      

    render() {
        // console.log(this.props.viewWidth);
        return (
            <Dialog
                    classes={{paper: this.props.classes.dialogPaper}}
                    open={this.props.purchaseDialogOpen}
                    onClose={this.props.closePurchaseDialog}
                >
                { this.state.loading
                    ? <div>
                        <div className={this.props.classes.loadingCheckoutMessage}>
                            One moment...<br/>
                            Directing you to <br/>
                            Our secure payment portal
                        </div>
                        {/* <OctonoteAnimation/> */}
                    </div>
                    : null
                }
                <Fade in={!this.state.loading} timeout={{ enter: 250, exit: 250 }}>
                    <div className={this.props.classes.root}>
                        <div className={this.props.classes.header}>
                            <h2 className={this.props.classes.title}>Octonote Premium</h2>
                            {this.props.viewWidth <= 800
                                ? <IconButton 
                                        aria-label="exit"
                                        size="small"
                                        classes={{root: this.props.classes.closeButtonRoot}}
                                        onClick={this.props.closePurchaseDialog}
                                    >
                                        <FontAwesomeIcon size="1x" icon={faAngleLeft} />
                                    </IconButton>
                                : null
                            }
                        </div>
                        <Grid className={this.props.classes.paymentSwitchWrapper} component="label" container alignItems="center" justifyContent="center" spacing={1}>
                            <Grid item>Monthly</Grid>
                            <Grid item>
                                <Switch disableRipple color="primary" checked={this.state.annualSwitchOn} onChange={this.toggleAnnualSwitch} name="annualSwitch" />
                            </Grid>
                            <Grid item>Annually</Grid>
                        </Grid>
                        <Paper className={this.props.classes.priceContainer}>
                            <div className={this.props.classes.priceDollarSign}>$</div>
                            <div className={this.props.classes.priceDollarAmount}>
                                {this.state.annualSwitchOn ? 4 : 5}
                            </div>
                            <div className={this.props.classes.priceCentsAmount}>99</div>
                            <div className={this.props.classes.pricePerMo}>/mo</div>
                        </Paper>
                        <div className={this.props.classes.billedAnnuallyText}>
                            {this.state.annualSwitchOn ? "(Billed as $59.88/yr)" : ""}
                        </div>
                        <Typography style={{textAlign: "center", paddingBottom: "10px"}}><b>Features</b></Typography>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" size="small">
                                <TableBody>
                                    {this.features.map(this.createTableRow)}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Button
                            variant="contained"
                            color="primary"
                            // color={this.props.user.type === "anon" ? "secondary" : "primary"}
                            // size="small"
                            className={this.props.classes.purchaseButton}
                            startIcon={<FontAwesomeIcon icon={faShoppingCart}/>}
                            onClick={this.onClickPurchase}
                            // autoFocus
                        >
                            Checkout
                        </Button>
                    
                    </div>
                </Fade>
                </Dialog>
        );
    }
}


const mapStateToProps = (state /*, ownProps*/) => {
    return {
        user: state.user,
        purchaseDialogOpen: state.visibility.purchaseDialogOpen,
        viewWidth: state.reactive.width
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closePurchaseDialog: () => dispatch(closePurchaseDialog())
    }
};

export default
connect(
    mapStateToProps,
    mapDispatchToProps
)(
withStyles(styles)
(PurchaseDialog));