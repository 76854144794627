import React from 'react';
import SearchBar from './searchBar';
import CompactSearchButton from './compactSearchButton';
// import ToggleSidebarButton from './toggleSidebarButton';
import UserButton from './userButton';
import logo from '../../assets/Logo.png';
import logo_sm from '../../assets/Logo_sm.png';
// import Sidebar from '../lib/sidebar/sidebar';

import Drawer from '@mui/material/Drawer';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { withResizeDetector } from 'react-resize-detector';
import { changeViewWidth } from '../../redux/actions';

const styles = theme => ({

    navWrapper: {
        top: 0,
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        height: "55px",
        width: "100%",
        // paddingBottom: "3px",
        // paddingTop: "3px",
        borderBottom: "2px solid #C0AD6A",
        backgroundColor: "#EEE5CE",
        // position: "sticky",
        zIndex: "4"
    },
    leftBlock: {
        display: "flex",
        width: "33.33vw",
        // margin: "auto",
        // backgroundColor: "red"
    },
    centerBlock: {
        display: "flex",
        width: "33.33vw",
        // margin: "auto",
        // backgroundColor: "blue"
    },
    rightBlock: {
        display: "flex",
        width: "33.33vw",
        margin: "auto 20px auto auto"
        // margin: "auto",
        // backgroundColor: "green"
    },
    logoMain: {
        // display: "flex",
        height: "100%",
        // height: "80%", //smaller screens?
        // width: "100%",
        marginRight: "10px",
        marginTop: "auto",
        marginBottom: "auto"
    },
    subSearchBarWrapper: {
        position: "absolute",
        // top: "46px",
        width: "200px"
    },
    logoText:{
        fontFamily: "Nodesto Caps Condensed",
        // color: "#58180d",
        color: "#d82000",
        // color: "#a20000",
        fontSize: "46px",
        marginLeft: "-19px",
        marginTop: "4px"
    }
})

const HIDE_SEARCH_BAR_WIDTH = 700;
const CONDENSE_LOGO_WIDTH = 470;

class NavBar extends React.Component {
    defaultState = {
        drawerSearchbarOpen: false
    }
    state = this.defaultState;

    componentDidUpdate(prevProps){
        if(this.props.width !== prevProps.width){
            this.props.changeViewWidth(this.props.width);
        }
    }

    viewAllNotes = () => {
        this.props.history.push("/");
    }

    getLogo = () => {
        return logo_sm;
        // return this.props.width > CONDENSE_LOGO_WIDTH ? logo : logo_sm;
    }

    toggleSubSearchBar = () => {
        this.setState({drawerSearchbarOpen: !this.state.drawerSearchbarOpen});
    }

    render() {
        return (
            <div className={this.props.classes.navWrapper}>
                {/* <Sidebar /> */}
                <div className={this.props.classes.leftBlock}>
                    {/* <ToggleSidebarButton/> */}
                    <img 
                        style={{cursor: "pointer"}}
                        className={this.props.classes.logoMain}
                        src={this.getLogo()}
                        alt="Dragonroll"
                        onClick={this.viewAllNotes}
                    />
                    <div className={this.props.classes.logoText}>
                        ragonroll
                    </div>
                </div>
                {/* {this.props.width >= HIDE_SEARCH_BAR_WIDTH
                    ? <div className={this.props.classes.centerBlock}>
                        <SearchBar/>
                      </div>
                    : null} */}
                <div 
                    className={this.props.classes.rightBlock}
                    style={this.props.width >= HIDE_SEARCH_BAR_WIDTH ? null : {width: "auto"}}
                >
                    {this.props.width >= HIDE_SEARCH_BAR_WIDTH
                        ? null
                        : <CompactSearchButton
                            toggleSubSearchBar={this.toggleSubSearchBar}
                        />
                    }
                    <UserButton/>
                </div>
                <Drawer
                    anchor="top"
                    open={this.state.drawerSearchbarOpen}
                    onClose={() => this.setState({drawerSearchbarOpen: false})}
                >
                    <SearchBar 
                        autoFocus
                        fullWidth
                        onSubmit={() => this.setState({drawerSearchbarOpen: false})}
                    />
                </Drawer>
            </div>
        );
    }
}

// const mapStateToProps = (state /*, ownProps*/) => {
//     return {
//         user: state.user,
//     }
// }

const mapDispatchToProps = dispatch => {
    return {
        changeViewWidth: width => dispatch(changeViewWidth(width))
    }
};

export default 
connect(
    // mapStateToProps,
    null,
    mapDispatchToProps
)(
withRouter(
withResizeDetector(
withStyles(styles)(
    NavBar
))));