// Import FirebaseAuth and firebase.
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { firebaseAuth } from '../services/firebaseAuth';
import { PRIVACY_URL, TERMS_URL } from '../lib/util/constants';

const styles = theme => ({
    userButton: {
        height: "40px",
        width: "40px",
        background: theme.palette.primary.main,
        color: "white",
        border: "solid rgba(255,255,255,.9)",
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    avatar: {
        color: "rgba(255,255,255,.9)",
        backgroundColor: theme.palette.primary.main, //Consider 2ndary?
        //Weird hack because Avatar likes to overflow the IconButton for some reason?
        width: "37.6px",
        height: "37.6px",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark
        }
    },
    userMenuSubheader: {
        display: "flex",
        padding: "5px"
    },
    userMenuSubheaderAvatar: {
        display: "block"
    },
    userMenuSubheaderText: {
        color: "rgba(255,255,255,.9)",
        paddingRight: "10px",
        paddingLeft: "10px",
        display: "block",
        fontSize: "18px",
        fontWeight: "500"
    }
})

class ActiveUser extends React.Component {
    state = {
        anchorEl: null
    }

    openUserMenu = e => {
        e.stopPropagation();
        this.setState({anchorEl: e.currentTarget});
    }

    closeUserMenu = e => {
        if(e) e.stopPropagation();
        this.setState({anchorEl: null});
    }

    openMyAccountPage = () => {
        const params = new URLSearchParams(this.props.location.search);
        params.append("page", "account");
        this.props.history.push(`?${params.toString()}`);
        this.closeUserMenu();
    }

    goToTerms = () => {
        window.open(TERMS_URL);
    }

    goToPrivacyPolicy = () => {
        window.open(PRIVACY_URL);
    }

    signOut = () => {
        firebaseAuth.signOut();
        this.setState({anchorEl: null});
        this.props.history.push('/'); //Go to main page
    }

    menuListHeader = () => {
        return (
            <>
                <ListSubheader className={this.props.classes.userMenuSubheader}>
                    <Typography
                        classes={{root: this.props.classes.userMenuSubheaderText}}
                    >{this.props.user.displayName}</Typography>
                    
                </ListSubheader>
                <Divider/>
            </>
        )
    }

    render() {
        return <>
            <IconButton
                variant="contained"
                color="primary"
                className={this.props.classes.userButton}
                onClick={this.openUserMenu}
                size="large">
                <Avatar
                    classes={{root: this.props.classes.avatar}}
                    alt={this.props.user.displayName}
                    src={this.props.user.imageUrl}
                />
            </IconButton>
            <Menu
                id="user-menu"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}} //consider vertical: -50
                getContentAnchorEl={null} //https://github.com/mui-org/material-ui/issues/7961#issuecomment-326116559
                // transformOrigin={{ vertical: 'top', horizontal: 'right',}}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.closeUserMenu}
                transitionDuration={200}
                MenuListProps={{
                    subheader: this.menuListHeader()
                }}
            >
                <MenuItem onClick={this.openMyAccountPage}>My Account</MenuItem>
                <MenuItem onClick={this.goToTerms}>Terms of Use</MenuItem>
                <MenuItem onClick={this.goToPrivacyPolicy}>Privacy Policy</MenuItem>
                <MenuItem onClick={this.signOut}>Sign Out</MenuItem>
            </Menu>
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(
withStyles(styles)(ActiveUser)))