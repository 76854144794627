import React from 'react';
import { ClickAwayListener } from '@mui/material';
import { withStyles } from '@mui/styles';

import Popper from '@mui/material/Popper';
// import Alert from '@mui/lab/Alert';

const styles = theme => ({
    popperContainer: {
        zIndex: theme.zIndex.modal
    },
    popperContents: {
        maxWidth: "250px",
        whiteSpace: 'pre-wrap',
        borderRadius: "5px",
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.background.paper,
    },
    popperContentsText: {
        wordBreak: 'break-word',
    }
})


class ErrorPopper extends React.Component {

    render(){
        return <ClickAwayListener onClickAway={this.props.resetErrorMessage}>
            <Popper
                className={this.props.classes.popperContainer}
                id="simple-popper"
                anchorEl={this.props.anchorEl} //anchorEl
                open={Boolean(this.props.errorMessage)} //errorMessage
                placement="bottom"
                disablePortal={true}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                    }
                }}
            >
                {/* <Alert 
                    // className={this.props.classes.popperContents}
                    classes={{root: this.props.classes.popperContents, message: this.props.classes.popperContentsText}}
                    variant="filled"
                    severity="error"
                >
                    {this.props.errorMessage}
                </Alert> */}
            </Popper>
        </ClickAwayListener>
    }

}
  
export default withStyles(styles)(ErrorPopper);