import { createTheme, adaptV4Theme } from '@mui/material/styles';

export const SMALL_BUTTON_STYLES = {
    height: 30,
    minHeight: 0,
    lineHeight: '30px',
    verticalAlign: 'middle',
    width: 30,
};

export const PRIMARY_MAIN = "#58180d";
export const SECONDARY_MAIN = "#e6ad00"; //Gold
export const TERTIARY_MAIN = "#9ba9b5"; //Silver

export default createTheme(adaptV4Theme({
  typography: {
    "fontFamily": "Bookinsanity"
  },
  palette: {
    primary: { main: PRIMARY_MAIN },
    // secondary: { main: cyan[400] }
    // secondary: { main: amber[900] }
    secondary: { main: SECONDARY_MAIN }, //#51C7C1 //#00dc4b //#5dd55d  //#0DC0FF
    text: {
        primary: "#000",
        secondary: "#000"
    },
    type: "light",
    background: {
        paper: "#EEE5CE",

    }
  },
  status: {
    danger: 'orange',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 800,
      lg: 1280,
      xl: 1920,
    },
  },
}));
//#00B8F5 blue primary
//#51C7C1 Teal 2ndary

//#5dd55d Contender green


// destructiveActionButton: {
//         color: "white",
//         background: red[600],
//         textTransform: "none",
//         whiteSpace: "nowrap",
//         marginRight: "10px",
//         marginTop: "20px",
//         '&:hover':{
//             background: red[800]
//         }
//     }