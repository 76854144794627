import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/material/Button';
import ActiveUser from '../../users/activeUser';
import { openSignInDialog } from '../../redux/actions';

const styles = theme => ({
    userButton: {
        display: "inline-block",
        marginLeft: "auto"
    },
    signInButton: {
        textTransform: "none",
        color: "white",
        fontSize: "16px",
        whiteSpace: "nowrap",
        border: "1px solid white"
    }
})

class UserButton extends React.Component{

    render() {
        return (
            <div className={this.props.classes.userButton}>
                {
                    this.props.user.email != null 
                    ? (<ActiveUser/>)
                    : (<Button
                        variant="contained"
                        color="primary"
                        className={this.props.classes.signInButton}
                        startIcon={<FontAwesomeIcon className='fa-sm' icon={faUser}/> }
                        onClick={this.props.openSignInDialog}
                    >
                        Sign In
                    </Button>)
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openSignInDialog: () => dispatch(openSignInDialog()),
    //   closeSidebar: () => dispatch(closeSidebar()),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
withStyles(styles)(UserButton))
