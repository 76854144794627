import React from 'react';

import SearchBar from './searchBar';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const styles = theme => ({
    wrapper: {
        margin: "auto 10px auto 0px",
        position: "relative"
    },
    button: {
        height: "40px",
        width: "40px",
        background: theme.palette.primary.main,
        color: "white",
        border: "1px solid white",
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    }
})

class CompactSearchButton extends React.Component {
    render() {
        return (
            <>
                <div className={this.props.classes.wrapper}>
                    <IconButton
                        classes={{root: this.props.classes.button}}
                        size="medium"
                        onClick={this.props.toggleSubSearchBar}
                    >
                        <FontAwesomeIcon className='fa-xs' icon={faSearch}/>
                    </IconButton>
                </div>
                {/* popper */}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // user: state.user
    }
}

export default connect(
    mapStateToProps,
    null
)(
withStyles(styles)(CompactSearchButton))