import * as React from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogExitButton from '../lib/util/dialogExitButton'
import { withRouter } from 'react-router-dom';

import MyAccount from './myAccount';
import { connect } from 'react-redux';

const styles = theme => ({
    root: {
        // zIndex: 5000
    },
    dialogPaper: {  
        // maxWidth: "100%",
        maxHeight: "100%",
        width: "100%",
        margin: "0"
    },
    plainButton: {
        color: "white",
        textTransform: "none",
        whiteSpace: "nowrap",
    }
})

class PageViewer extends React.Component{

    closeDialog = () => {
        const params = new URLSearchParams(this.props.location.search);
        params.delete('page');
        this.props.history.push(`?${params.toString()}`);
    }

    pageSpecificContents = () => {
        if(this.props.viewPageId === 'account'){
            return <MyAccount/>;
        }else{
            return null;
        }
    }

    innerContents = () => {
        return <div>
            {this.pageSpecificContents()}
            <DialogActions>
                <Button
                    className={this.props.classes.plainButton}
                    onClick={this.closeDialog}
                    color="primary"
                    variant="contained"
                >
                    Done
                </Button>
            </DialogActions>
        </div>
    }

    render(){
        return (
            <Dialog
                classes={{
                    root: this.props.classes.root,
                    paper: this.props.classes.dialogPaper
                }}
                open={Boolean(this.props.viewPageId)}
                onClose={this.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogExitButton
                    onClick={this.closeDialog}
                />
                {Boolean(this.props.viewPageId) //Smooth transition out
                ? this.innerContents()
                : null}
            </Dialog>
        )
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        viewPageId: state.location.viewPageId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // removeNoteFromList : (noteId) => dispatch(removeNoteFromList(noteId)),
    }
};

export default
withRouter(
connect(
    mapStateToProps,
    mapDispatchToProps
)(
withStyles(styles)
(PageViewer)));