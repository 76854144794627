import React from 'react';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClipboard, faImage, faPencil, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

const styles = theme => ({
    actionWrapper: {
        borderTop: "2px solid #C0AD6A",
        padding: "5px 15px",
        maxWidth: "400px",
        margin: "auto auto 65px auto"
    },
    actionContainer: {
        display: "flex",
        // backgroundColor: "#EEE5CE",
        // margin: "auto",
        width: "100%"
    },
    actionButton: {
        width: "40px",
        height: "40px",
        color: "#58180D"
    },
    tooltip: {
        fontSize: "16px",
        fontFamily: "Scaly Sans Caps",
        color: "#fff"
    },
    addImageBar: {
        display: "flex"
    },
    mjButton: {
        fontFamily: "Scaly Sans Caps",
        textTransform: "none",
        lineHeight: "1",
        borderRadius: "10px",
        padding: "5px 10px",
        borderRadius: "10px",
        height: "min-content",
        margin: "auto 0px auto 5px",
        background: "lightgrey"
    },
    imageCheckButton: {
        width: "40px",
        height: "40px",
        color: "#58180D",
        margin: "auto 0px auto 5px"
    }
})

class ActionBar extends React.Component{
    state = {
        addImageBarOpen: false,
        imageUrl: ""
    }

    copyOnClick = () => {
        this.setState({copyTooltipOpen: true})
        const text = `${this.props.title}\n${this.props.content}`
        navigator.clipboard.writeText(text);
    }

    copyMjPrompt = () => {
        this.setState({copyTooltipOpen: true})
        navigator.clipboard.writeText(this.props.imagePrompt);
    }

    toggleAddImageBarOpen = () => {
        this.setState({addImageBarOpen: !this.state.addImageBarOpen});
    }

    renderCopyButton = () => {
        return <Tooltip
            classes={{tooltip: this.props.classes.tooltip}}
            onClose={() => this.setState({copyTooltipOpen: false})}
            open={this.state.copyTooltipOpen}
            placement="bottom"
            leaveDelay={600}
            title="Text Copied!"
            arrow
        >
            <IconButton
                // sx={{marginLeft: "auto"}}
                className={this.props.classes.actionButton}
                onClick={this.copyOnClick}
            >
                <FontAwesomeIcon icon={faCopy}/>
            </IconButton>
        </Tooltip>
    }

    renderEditButton = () => {
        return <IconButton
                sx={{marginRight: "5px", marginLeft: "auto"}}
                className={this.props.classes.actionButton}
                onClick={this.toggleEdit}
            >
                <FontAwesomeIcon icon={faPencil}/>
            </IconButton>
    }

    renderImageButton = () => {
        return <IconButton
                sx={{marginRight: "5px"}}
                className={this.props.classes.actionButton}
                onClick={this.toggleAddImageBarOpen}
            >
                <FontAwesomeIcon icon={faImage}/>
            </IconButton>
    }

    renderAddImageBar = () => {
        return <div className={this.props.classes.addImageBar}>
            <TextField
                id="imageUrlInput"
                size="small"
                margin="dense"
                className={this.props.classes.imageUrlInput}
                label="Image URL"
                variant="outlined"
                value={this.state.imageUrl}
                autoFocus
                onChange={e => this.setState({imageUrl: e.target.value})}
            />
            <IconButton
                sx={{margin: "auto"}}
                className={this.props.classes.imageCheckButton}
                onClick={() => {
                    this.props.setImage(this.state.imageUrl);
                    this.toggleAddImageBarOpen();
                    window.scrollTo(0,0);
                }}
            >
                <FontAwesomeIcon icon={faCheck}/>
            </IconButton>
            {this.renderMjCopySection()}
        </div>
    }

    renderMjCopySection = () => {
        return <Button
            className={this.props.classes.mjButton}
            variant="outlined"
            startIcon={
                <FontAwesomeIcon style={{fontSize: "16px"}} icon={faClipboard}/>
            }
            onClick={this.copyMjPrompt}
            >
                Copy Midjourney prompt
            </Button>
    }

    render() {
        return <div className={this.props.classes.actionWrapper}>
            {this.state.addImageBarOpen
                ? this.renderAddImageBar()
                : null
            }
            <div className={this.props.classes.actionContainer}>
                {this.renderEditButton()}
                {this.renderImageButton()}
                {this.renderCopyButton()}
                {/* {this.renderCopyButton()} */}
            </div>
        </div>
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        // userId: state.user._id,
        // viewWidth: state.reactive.width
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    //   closeActiveNote: noteId => dispatch(closeActiveNote(noteId))
    }
};
  
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ActionBar)));