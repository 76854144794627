import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import GenTypeDropdown from './genTypeDropdown';

// const themeColors = ["#00B8F5","#FFC15E","#9EE493","#FCEC52"];

const styles = theme => ({
    createNoteButton: {
        textTransform: "none"
    },
    wrapper: {
        position: "fixed",
        bottom:  "0px",
        transform: "translateX(-50%)",
        left: "50%",
        width: "100%",
        // minWidth: "100px",
        marginTop: "auto",
        minHeight: "40px",
        padding: "10px",
        display: "flex",
        background: theme.palette.primary.main,
        // borderRadius: "3px",
        // padding: "10px 20px",
        // border: "1px solid #C0AD6A",
        // boxShadow: "1px 6px 9px rgba(33,33,33)"
    },
    container: {
        display: "flex",
        margin: "auto"
    },
    button: {
        height: "40px",
        width: "40px",
        color: "#fff"
    }
})

class CreationSection extends React.Component{

    state = {
        typeSelected: "character",
    }

    createNewNote = async (e) => {
        if(e) e.stopPropagation();
        // const createdNote = await note.create(isFirstNote);

        //Assume new note is being added to book being viewed (if user can add to this book)

        // const params = new URLSearchParams(this.props.location.search);
        // params.set('note', createdNote._id);
        // this.props.history.push(`?${params.toString()}`);
        
        this.props.generateContent(this.state.typeSelected);

        // return createdNote._id;
        return;
        // window.scroll(0,0);
        // setTimeout(() => { //Hack to ensure it is open before focusing
        //     document.querySelector('.newNoteTitle').focus();
        // }, 100)
    }

    render() {
        return (
            <div className={this.props.classes.wrapper}>
                <div className={this.props.classes.container}>
                    <GenTypeDropdown
                        supressEmpty
                        value={this.state.typeSelected}
                        onChange={e => this.setState({typeSelected: e.target.value}, this.createNewNote)} //Change type and create the note immediately
                    />
                    <IconButton
                        // id="createNoteButton"
                        className={this.props.classes.createNoteButton}
                        classes={{root: this.props.classes.button}}
                        size="large"
                        onClick={this.createNewNote}
                    >
                        <FontAwesomeIcon style={{fontSize: "24px"}}className='fa-xl' icon={faPlus}/>
                    </IconButton>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // user: state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default withRouter(
connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
withStyles(styles)(CreationSection)))
