import { Types } from './actions';

export const defaultState = {
    _id: "",
    email: "",
    toBeDeleted: false,
    imageUrl: null,
    displayName: "",
    bookshelf: {},
    state: {
        noteCount: 0,
        dataSize: 0
    }
};

export const userReducer = (state = defaultState, action) => {
    switch (action.type) {

        case Types.USER_LOGIN: {
            if(action.user){
                return Object.assign({}, state, {...action.user});
            }else{
                return state;
            }
        }

        case Types.UPDATE_BOOKSHELF: {
            if(action.bookshelf){
                return Object.assign({}, state, {bookshelf: action.bookshelf});
            }else{
                return state;
            }
        }

        case Types.UPDATE_DISPLAY_NAME: {
            if(action.displayName){
                return Object.assign({}, state, {displayName: action.displayName});
            }else{
                return state;
            }
        }

        case Types.USER_ADJUST_USAGE: { //Expects negative numbers when note deleted
            if(action.noteCount || action.dataSize){
                return Object.assign({}, state, {
                    state: { // state . user state
                        noteCount: state.state.noteCount + action.noteCount, 
                        dataSize: state.state.dataSize + (action.dataSize || 0) //Default 0 in case no file
                    }
                });
            }else{
                return state;
            }
        }

        case Types.INIT_USER_DELETION: {
            return Object.assign({}, state, {toBeDeleted: true});
        }

        default: {
            return state;
        }
    }
}
