import React from 'react';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faEmptyHeart } from '@fortawesome/free-regular-svg-icons';

const styles = theme => ({
    headerWrapper: {
        zIndex: "5",
        backgroundColor: "#EEE5CE",
        borderBottom: "2px solid #C0AD6A",
        position: "sticky",
        top: "0px",
    },
    headerContainer: {
        display: "flex",
        maxWidth: "400px",
        margin: "auto",
        padding: "5px 15px",
    },
    title: {
        // fontFamily: "Nodesto Caps Condensed",
        fontFamily: "Mr Eaves Small Caps",
        fontSize: "32px",
        color: "#58180D",
        // borderBottom: "2px solid #C0AD6A",
        fontWeight: "800",
        margin: "auto auto auto 0px",
        textAlign: "center",
        overflowX: "auto"
    },
    actionWrapper: {
        display: "flex",
        marginTop: "auto",
        marginBottom: "auto",
    },
    actionContainer: {
        display: "flex",
        // backgroundColor: "#EEE5CE",
        // margin: "auto",
        width: "100%"
    },
    actionButton: {
        width: "40px",
        height: "40px",
        color: "#58180D"
    },
    tooltip: {
        fontSize: "16px",
        fontFamily: "Scaly Sans Caps",
        color: "#fff"
    }
})

class ContentHeader extends React.Component{
    state = {
        loved: false,
        shareTooltipOpen: false,
        copyTooltipOpen: false,
    }

    heartOnClick = () => {
        this.setState({loved: !this.state.loved})
    }

    shareOnClick = () => {
        // this.setState({shareTooltipOpen: true})
        const url = `https://www.dragonroll.net/a9s8ydhj`
        // navigator.clipboard.writeText(text);
        navigator.share({
            url,
            title: this.props.title,
            text: this.props.content
        });
    }

    renderHeartButton = () => {
        return <IconButton
            // sx={{marginRight: "5px"}}
            className={this.props.classes.actionButton}
            onClick={this.heartOnClick}
        >
            <FontAwesomeIcon 
                icon={this.state.loved ? faHeart : faEmptyHeart}
                style={{color: this.state.loved ? "#a20000" : null}}
            />
        </IconButton>
    }

    renderShareButton = () => {
        return <Tooltip
            classes={{tooltip: this.props.classes.tooltip}}
            onClose={() => this.setState({shareTooltipOpen: false})}
            open={this.state.shareTooltipOpen}
            placement="bottom"
            leaveDelay={600}
            title="Link Copied!"
            arrow
        >
            <IconButton
                sx={{marginRight: "auto"}}
                className={this.props.classes.actionButton}
                onClick={this.shareOnClick}
            >
                <FontAwesomeIcon icon={faShareAlt}/>
            </IconButton>
        </Tooltip>
    }

    render = () => {
        return <div className={this.props.classes.headerWrapper}>
            <div className={this.props.classes.headerContainer}> 
                <div className={this.props.classes.title}>
                    {this.props.title}
                </div>
                <div className={this.props.classes.actionWrapper}>
                    <div className={this.props.classes.actionContainer}>
                        {this.renderHeartButton()}
                        {this.renderShareButton()}
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        // userId: state.user._id,
        // viewWidth: state.reactive.width
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    //   closeActiveNote: noteId => dispatch(closeActiveNote(noteId))
    }
};
  
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ContentHeader)));