import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    dropdown: {
        margin: "auto 0px auto 10px",
        width: "150px",
    },
})

class GenTypeDropdown extends React.Component{

    genTypeMap = {
        character: "Character",
        location: "Location"
    }

    renderMenuOptions = () => {
        return Object.keys(this.genTypeMap).map(key => {
            return <MenuItem
                sx={{fontFamily: "Zatanna Misdirection"}}
                value={key}
            >
                {this.genTypeMap[key]}
            </MenuItem>
        })
    }

    render() {
        return <FormControl className={this.props.classes.dropdown}  size="small">
            <Select
                value={this.props.value}
                onChange={this.props.onChange}
                sx={{color: "white", border: "1px solid white", fontFamily: "Zatanna Misdirection"}}
            >
                {this.renderMenuOptions()}

                {this.props.supressEmpty
                    ? null
                    : <MenuItem value={"empty"}>Empty Note</MenuItem>
                }
            </Select>
        </FormControl>
    }
}

  
export default withStyles(styles)(GenTypeDropdown);