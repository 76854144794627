import React from 'react';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OctonoteAnimation from '../../lib/octonoteAnimation';
import { randomLoadingMessage } from '../../lib/util/general';
import ContentHeader from './contentHeader';
import ActionBar from './actionBar';

const styles = theme => ({
    wrapper: {
        // marginTop: "30px",
        width: "100%",
        margin: "auto",
    },
    content: {
        // fontFamily: "Scaly Sans",
        fontSize: "18px",
        color: "black",
        margin: "10px auto 5px auto",
        padding: "0px 15px",
        lineHeight: "22px",
        whiteSpace: "pre-wrap",
        maxWidth: "400px"
    },
    contentLoadingMessage: {
        fontStyle: "italic",
        maxWidth: "250px",
        position: "absolute",
        top: "calc(50% - 75px)",
        transform: "translateX(-50%)",
        left: "50%",
        textAlign: "center"
    },
    image: {
        display: "block",
        maxHeight: "450px",
        maxWidth: "100%",
        width: "auto",
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)"
    }
})

class ContentViewer extends React.Component{
    state = {
        loading: false,
        contentLoadingMessage: "",
        title: "Welcome!",
        content: "Dragonroll helps you find the inspiration you need to craft your world. Use the button below to choose what to create!",
        imagePrompt: "",
        imageUrl: ""
    }

    setLoading = () => {
        this.setState({
            loading: true,
            contentLoadingMessage: randomLoadingMessage(),
            title: "",
            content: "",
            imageUrl: "",
            imagePrompt: ""
        });
    }

    setContent = (data) => {
        this.setState({loading: false, ...data});
    }

    setImage = (url) => {
        this.setState({imageUrl: url});
    }

    render() {
        return <div>
            {this.state.loading
                ? 
                <div>
                    <div className={this.props.classes.contentLoadingMessage}>
                        {this.state.contentLoadingMessage}
                    </div>
                    <OctonoteAnimation/>
                </div>
                : <div className={this.props.classes.wrapper}>
                    <ContentHeader
                        title={this.state.title}
                    />
                    {this.state.imageUrl
                        ? <img 
                            src={this.state.imageUrl}
                            className={this.props.classes.image}
                        />
                        : null
                    }
                    <div className={this.props.classes.content}>
                        {this.state.content}
                    </div>
                    <ActionBar
                        title={this.state.title}
                        content={this.state.content} //For copying
                        imagePrompt= {this.state.imagePrompt}
                        setImage={this.setImage}
                    />
                </div>
            }
        </div>
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        // userId: state.user._id,
        // viewWidth: state.reactive.width
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    //   closeActiveNote: noteId => dispatch(closeActiveNote(noteId))
    }
};
  
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    {forwardRef: true}
)(withStyles(styles)(ContentViewer)));