export function getObjProp(obj, path) {
    var p = path.split('.');
    var len = p.length;

    for (var i = 0; i < len; i++) {
        if (obj != null) {
            obj = obj[p[i]];
        } else {
            return null; //This can happen if path leads to an undefined object
        }
    }
    return obj;
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//ref https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const randomLoadingMessage = () => {
    const list = [
        "Hold on to your dragon eggs, this world building is no joke...",
        "Igniting the forge of imagination to craft your world...",
        "Brewing a magical elixir of awesomeness for your world...",
        "Consulting the ancient tomes of worldbuilding to craft your world...",
        "Summoning the spirits of adventure to imbue your world with greatness...",
        "Battling the forces of boredom to create the most epic content for your world...",
        "Infusing your world with the spark of life and adventure...",
        "Fusing the elements of fantasy to create a world of wonder...",
        "Sculpting your world's content with the precision of a master craftsman...",
        "Rummaging through the loot to find the perfect content for your world..."
    ];
    return list[Math.floor(Math.random()*list.length)];
}