import * as React from 'react';
import { adaptV4Theme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { ThemeProvider, StyledEngineProvider, createMuiTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Fade from '@mui/material/Fade';

import { faAngleDown, faAngleLeft, faAngleRight, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { red } from '@mui/material/colors';
// import { getDataSizeText, getNoteCountText } from '../lib/usageText';
import { user } from '../services/serverComm';
import { firebaseAuth, firestore } from '../services/firebaseAuth';
import { openDeleteAccountDialog, updateDisplayName } from '../redux/actions';
import { BASE_URL } from '../lib/util/constants';

const customTheme = createMuiTheme(adaptV4Theme({
    palette: {
      primary: {main: "#cc0029"},
    }
}));

const styles = theme => ({
    sectionHeader: {
        fontSize: "24px",
        fontWeight: "300",
        borderBottom: "1px solid #d7dbdb"
    },
    danger: {
        color: red['800']
    },
    infoSection: {
        marginTop: "5px",
        marginBottom: "20px"
    },
    infoLabel: {
        fontSize: "20px",
        fontWeight: "300",
        lineHeight: "1.6"
    },
    displayNameInput: {
        marginBottom: "0",
        marginLeft: "5px",
        maxWidth: "200px"
    },
    expandDangerSectionButton: {
        marginLeft: "20px",
        lineHeight: "0",
        height: "20px",
        marginBottom: "4px"
    },
    button: {
        textTransform: "none",
        whiteSpace: "nowrap",
        marginTop: "15px",
        color: "white"
    }
})

class MyAccount extends React.Component{

    defaultState = {
        userDisplayName: "",
        dangerSectionOpen: false,
        sendingEmail: false,
        emailSent: false
    }
    state = this.defaultState;

    componentDidMount(){
        this.setState({userDisplayName: this.props.user.displayName});
    }

    componentDidUpdate(prevProps){
        //Accounts for user switching, direct nav to account page, and when a name is actually updated
        if(this.props.user?.displayName !== prevProps.user?.displayName){
            this.setState({userDisplayName: this.props.user.displayName});
        }
    }

    updateUserDisplayNameState = (e) => {
        const target = e.target;

        //Remove spaces and keep cursor in place
        const position = target.selectionStart; // Capture initial position
        target.value = target.value.replace(/\s/g, '');
        target.selectionEnd = position; // Set the cursor back to the initial position.

        this.setState({userDisplayName: e.target.value});
    }

    displayNameUpdateButton = () => {
        this.setState({displayNameUpdateButton: true});
    }

    onBlurDisplayNameInput = () => {
        //Treat it like a cancel but do it after Update button had a chance to be pressed
        setTimeout(() => 
            this.setState({displayNameUpdateButton: false, userDisplayName: this.props.user.displayName})
        , 500)
    }

    updateDisplayNameButtonVisible = () => {
        return this.state.displayNameUpdateButton && this.props.user.displayName !== this.state.userDisplayName;
    }

    updateUserDisplayName = (e) => {
        e.stopPropagation();
        user.update(this.props.user._id, {displayName: this.state.userDisplayName});
        this.props.updateDisplayName(this.state.userDisplayName);
    }

    exportData = () => {
        // note.exportData();
    }

    resetPasswordLabel = () => {
        if(this.state.sendingEmail){
            return "Sending Email..."
        }else{
            if(this.state.emailSent){
                return "Sent!"
            }else{
                return "Reset Password"
            }
        }
    }

    resetPassword = () => {
        this.setState({sendingEmail: true}, async () => {
            await firebaseAuth.sendPasswordResetEmail(firebaseAuth.currentUser.email, {
                url: BASE_URL
            });
            setTimeout(() => { //Slow it down for user to read
                this.setState({sendingEmail: false, emailSent: true}, () => {
                    setTimeout(() => this.setState({emailSent: false}), 1500);
                })
            }, 1500)
        })
        
    }

    manageBilling = async () => {
        const snapshot = await firestore
            .collection('customers')
            .doc(this.props.user._id)
            .get();

        const customerId = snapshot.data().stripeId;
        const url = await user.redirectTostripeCustomerPortal(customerId);
        console.log(url);
        window.location = url;
    }

    toggleDangerSectionOpen = () => {
        this.setState({dangerSectionOpen: !this.state.dangerSectionOpen});
    }
    
    render(){
        return (
            <div>
                <DialogTitle id="alert-dialog-title">
                    My Account
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div className={this.props.classes.sectionHeader}>Account Info</div>
                    <div className={this.props.classes.infoSection}>
                        <span className={this.props.classes.infoLabel} style={{verticalAlign: "bottom"}}>Display Name: </span>
                            <TextField
                                classes={{root: this.props.classes.displayNameInput}}
                                inputProps={{ maxLength: 64 }}
                                margin="dense"
                                value={this.state.userDisplayName}
                                onChange={this.updateUserDisplayNameState}
                                onFocus={this.displayNameUpdateButton}
                                onBlur={this.onBlurDisplayNameInput}
                            />
                            <Fade in={this.updateDisplayNameButtonVisible()}>
                                <span>{this.updateDisplayNameButtonVisible() 
                                    ? <Button 
                                        className={this.props.classes.button}
                                        style={{marginTop: "0px", marginLeft: "15px"}}
                                        color="primary"
                                        variant="contained"
                                        onClick={this.updateUserDisplayName}
                                    >
                                        Update
                                    </Button>
                                    : null
                                }</span>
                            </Fade>
                            <br/>
                        <span className={this.props.classes.infoLabel}>Email: </span>{this.props.user.email}<br/>
                        {/* <span className={this.props.classes.infoLabel}>Note Count: </span>
                            {getNoteCountText(this.props.user.type, this.props.user.state.noteCount)}<br/>
                        <span className={this.props.classes.infoLabel}>Data Usage: </span>
                            {getDataSizeText(this.props.user.type, this.props.user.state.dataSize)} */}
                    </div>

                    <div className={this.props.classes.sectionHeader}>Actions</div>
                    <div className={this.props.classes.infoSection}>
                        {/* <Button
                            className={this.props.classes.button}
                            onClick={this.exportData}
                            color="primary"
                            variant="contained"
                            size="small"
                        >
                            Download My Data
                        </Button> <br/> */}
                        {this.props.user.providerId === "password"
                        ? <><Button
                                disabled={this.state.sendingEmail}
                                className={this.props.classes.button}
                                onClick={this.resetPassword}
                                color="primary"
                                variant="contained"
                                size="small"
                            >
                                {this.resetPasswordLabel()}
                            </Button> <br/> </>
                        : null
                        }
                        {this.props.user.type === 'premium' 
                            ? <><Button
                                    className={this.props.classes.button}
                                    onClick={this.manageBilling}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                >
                                    Manage Billing
                                </Button> <br/></>
                            : null}
                    </div>

                    <div className={`${this.props.classes.sectionHeader} ${this.props.classes.danger}`}>
                        Danger Zone
                        <IconButton
                            disableRipple
                            aria-label="exit"
                            // size="small"
                            classes={{root:this.props.classes.expandDangerSectionButton}}
                            onClick={this.toggleDangerSectionOpen}
                            size="large">
                            <FontAwesomeIcon size="1x" icon={
                                this.state.dangerSectionOpen ? faAngleDown : faAngleRight} />
                        </IconButton>
                    </div>
                    <Fade in={this.state.dangerSectionOpen}>
                        {this.state.dangerSectionOpen 
                        ? <div className={this.props.classes.infoSection}>
                                <StyledEngineProvider injectFirst>
                                    <ThemeProvider theme={customTheme}>
                                        <Button
                                            disabled={this.props.user.toBeDeleted}
                                            className={this.props.classes.button}
                                            onClick={this.props.openDeleteAccountDialog}
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                        >
                                            {this.props.user.toBeDeleted 
                                                ? "Processing Deletion Request..."
                                                : "Delete Account"
                                            }
                                        </Button>
                                    </ThemeProvider>
                                </StyledEngineProvider>
                            </div>
                        : <div/>}
                    </Fade>
                </DialogContent>
            </div>
        );
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openDeleteAccountDialog: () => dispatch(openDeleteAccountDialog()),
        updateDisplayName: (displayName) => dispatch(updateDisplayName(displayName))
        // removeNoteFromBook : (noteId, bookId) => dispatch(removeNoteFromBook(noteId, bookId)),
        // removeNoteFromList : (noteId) => dispatch(removeNoteFromList(noteId)),
        // adjustUserUsage : (noteCount, dataSize) => dispatch(adjustUserUsage(noteCount, dataSize))
    }
};

export default
connect(
    mapStateToProps,
    mapDispatchToProps
)(
withStyles(styles)
(MyAccount));