import React from 'react';
import { withStyles } from '@mui/styles';
import { PRIMARY_MAIN, SECONDARY_MAIN, TERTIARY_MAIN } from '../theme';

const triangleBase = {
    width: "0px",
    height: "0px",
    borderLeft: "60px solid transparent",
    borderRight: "60px solid transparent",
    animation: "rotation 1s infinite cubic-bezier(.46,.33,.56,.66)",
}

const styles = theme => ({
    mainWrapper: {
        position: "absolute",
        top: "50%",
        bottom: "50%",
        right: "50%",
        left: "50%",
        zIndex: 9999,
        background: "white",
        transform: "scale(.2)"
        // animation: "scaleIn 2s infinite linear"
    },
    mainWrapperLg: {
        position: "absolute",
        top: "35%",
        bottom: "50%",
        right: "50%",
        left: "50%",
        zIndex: 9999,
        background: "white",
        transform: "scale(.2)"
    },
    sizeDefault: {
        transform: "scale(.2)"
    },
    sizeMedium: {
        transform: "scale(.3)"
    },
    sizeLarge: {
        transform: "scale(1)"
    },
    triangle1: {
        ...triangleBase,
        borderBottom: `55px solid ${PRIMARY_MAIN}`,
    },
    triangle2: {
        ...triangleBase,
        borderBottom: `55px solid ${SECONDARY_MAIN}`
    },
    triangle3: {
        ...triangleBase,
        borderBottom: `55px solid ${PRIMARY_MAIN}`,
    },
    triangle4: {
        ...triangleBase,
        borderBottom: `55px solid ${SECONDARY_MAIN}`
    },
    wrapper1: {  
        position: "absolute",
        top: "0px",
        left: "-45px",
        transform: "rotateY(0deg) rotate(45deg)"
    },
    wrapper2: {
        position: "absolute",
        top: "39px",
        left: "7px",
        transform: "rotateY(0deg) rotate(90deg)"
    },
    wrapper3: {
        position: "absolute",
        top: "103.5px",
        left: "16px",
        transform: "rotateY(0deg) rotate(135deg)"
    },
    wrapper4: {
        position: "absolute",
        top: "155.5px",
        left: "-22px",
        transform: "rotateY(0deg) rotate(180deg)"
    },
    wrapper5: {  
        position: "absolute",
        top: "165px",
        left: "-87px",
        transform: "rotateY(0deg) rotate(225deg)"
    },
    wrapper6: {
        position: "absolute",
        top: "125px",
        left: "-139px",
        transform: "rotateY(0deg) rotate(270deg)"
    },
    wrapper7: {
        position: "absolute",
        top: "61px",
        left: "-148.5px",
        transform: "rotateY(0deg) rotate(315deg)"
    },
    wrapper8: {
        position: "absolute",
        top: "9px",
        left: "-109.5px",
        transform: "rotateY(0deg) rotate(360deg)"
    }
})


class OctonoteAnimation extends React.Component {
    
    getSize = () => {
        switch(this.props.size){
            case "lg": return this.props.classes.sizeLarge;
            case "m": return this.props.classes.sizeMedium;
            case "s":
            default: return this.props.classes.sizeDefault
        }
    }

    render () {
        return <div className={`${
                this.props.size === 'lg'
                ? this.props.classes.mainWrapperLg
                : this.props.classes.mainWrapper} 
                ${this.getSize()}`
            }>
            <div className={this.props.classes.wrapper1}>
                <div className={this.props.classes.triangle1}></div>
            </div>
            <div className={this.props.classes.wrapper2}>
                <div className={this.props.classes.triangle2}></div>
            </div>
            <div className={this.props.classes.wrapper3}>
                <div className={this.props.classes.triangle3}></div>
            </div>
            <div className={this.props.classes.wrapper4}>
                <div className={this.props.classes.triangle4}></div>
            </div>
            <div className={this.props.classes.wrapper5}>
                <div className={this.props.classes.triangle1}></div>
            </div>
            <div className={this.props.classes.wrapper6}>
                <div className={this.props.classes.triangle2}></div>
            </div>
            <div className={this.props.classes.wrapper7}>
                <div className={this.props.classes.triangle3}></div>
            </div>
            <div className={this.props.classes.wrapper8}>
                <div className={this.props.classes.triangle4}></div>
            </div>
        </div>
    }
}
  
export default withStyles(styles)(OctonoteAnimation);