import React from 'react';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const styles = theme => ({
    buttonWrapper: {
        position: "absolute",
        top: "5px",
        right: "5px"
    }
})

class DialogExitButton extends React.Component {
    render(){
        return (
            <div className={this.props.classes.buttonWrapper}>
                <IconButton aria-label="close" onClick={this.props.onClick} size="large">
                    <CloseIcon  />
                </IconButton>
            </div>
        );
    }
}
  
export default withStyles(styles)(DialogExitButton);