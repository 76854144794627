//TODO: Consider refactoring REMOVE + FINISH with one action where action is called with 'null' for finished.

// Actions
export const Types = {
    //visibilityReducer
    // SIDEBAR_OPEN: 'SIDEBAR_OPEN',
    // SIDEBAR_CLOSED: 'SIDEBAR_CLOSED',
    OPEN_SIGNIN_DIALOG: 'OPEN_SIGNIN_DIALOG',
    CLOSE_SIGNIN_DIALOG: 'CLOSE_SIGNIN_DIALOG',
    OPEN_PURCHASE_DIALOG: 'OPEN_PURCHASE_DIALOG',
    CLOSE_PURCHASE_DIALOG: 'CLOSE_PURCHASE_DIALOG',
    OPEN_DELETE_ACCOUNT_DIALOG: 'OPEN_DELETE_ACCOUNT_DIALOG',
    CLOSE_DELETE_ACCOUNT_DIALOG: 'CLOSE_DELETE_ACCOUNT_DIALOG',

    //userReducer
    USER_LOGIN: 'USER_LOGIN',
    UPDATE_BOOKSHELF: 'UPDATE_BOOKSHELF',
    UPDATE_DISPLAY_NAME: 'UPDATE_DISPLAY_NAME',
    USER_ADJUST_USAGE: 'USER_ADJUST_USAGE',
    INIT_USER_DELETION: 'INIT_USER_DELETION',

    //reactiveReducer
    CHANGE_VIEW_WIDTH: 'CHANGE_VIEW_WIDTH'
};


// VISIBILITY REDUCER
export const openSidebar = () => {
    return { type: Types.SIDEBAR_OPEN }
}

export const closeSidebar = () => {
    return { type: Types.SIDEBAR_CLOSED }
}

export const openSignInDialog = (signInRegisterSwitchOn) => {
    return { type: Types.OPEN_SIGNIN_DIALOG, signInRegisterSwitchOn }
}

export const closeSignInDialog = () => {
    return { type: Types.CLOSE_SIGNIN_DIALOG }
}

export const openPurchaseDialog = () => {
    return { type: Types.OPEN_PURCHASE_DIALOG }
}

export const closePurchaseDialog = () => {
    return { type: Types.CLOSE_PURCHASE_DIALOG }
}

export const openDeleteAccountDialog = () => {
    return { type: Types.OPEN_DELETE_ACCOUNT_DIALOG }
}

export const closeDeleteAccountDialog = () => {
    return { type: Types.CLOSE_DELETE_ACCOUNT_DIALOG }
}


// USER REDUCER
export const userLogin = user => {
    return {type: Types.USER_LOGIN, user}
}

export const updateBookshelf = bookshelf => {
    return { type: Types.UPDATE_BOOKSHELF, bookshelf }
}

export const updateDisplayName = displayName => {
    return { type: Types.UPDATE_DISPLAY_NAME, displayName }
}

export const adjustUserUsage = (noteCount, dataSize) => {
    return { type: Types.USER_ADJUST_USAGE, noteCount, dataSize }
}

export const initUserDeletion = () => {
    return {type: Types.INIT_USER_DELETION };
}

//REACTIVE REDUCER
export const changeViewWidth = width => {
    return { type: Types.CHANGE_VIEW_WIDTH, width }
}