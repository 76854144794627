import { Types } from './actions';

export const defaultState = {
    sidebarOpen: Boolean(window.visualViewport.width >= 700),
    createNoteOpen: true,
    signInDialogOpen: false,
    signInRegisterSwitchOn: false,
    checkoutDialogOpen: false,
    purchaseDialogOpen: false,
    deleteAccountDialogOpen: false
};

export const visibilityReducer = (state = defaultState, action) => {
    switch (action.type) {

        case Types.SIDEBAR_OPEN: {
            return Object.assign({}, state, { sidebarOpen: true });
        }

        case Types.SIDEBAR_CLOSED: {
            return Object.assign({}, state, { sidebarOpen: false });
        }

        case Types.CREATE_NOTE_OPEN: {
            return Object.assign({}, state, { createNoteOpen: true });
        }

        case Types.CREATE_NOTE_CLOSED: {
            return Object.assign({}, state, { createNoteOpen: false });
        }

        case Types.OPEN_SIGNIN_DIALOG: {
            let update = { 
                signInDialogOpen: true,
                signInRegisterSwitchOn: Boolean(action.signInRegisterSwitchOn)
            };
            return Object.assign({}, state, update);
        }

        case Types.CLOSE_SIGNIN_DIALOG: {
            let update = { 
                signInDialogOpen: false,
                signInRegisterSwitchOn: false
            };
            return Object.assign({}, state, update);
        }

        case Types.OPEN_PURCHASE_DIALOG: {
            return Object.assign({}, state, {purchaseDialogOpen: true});
        }

        case Types.CLOSE_PURCHASE_DIALOG: {
            return Object.assign({}, state, {purchaseDialogOpen: false});
        }

        case Types.OPEN_DELETE_ACCOUNT_DIALOG: {
            return Object.assign({}, state, {deleteAccountDialogOpen: true});
        }

        case Types.CLOSE_DELETE_ACCOUNT_DIALOG: {
            return Object.assign({}, state, {deleteAccountDialogOpen: false});
        }

        default: {
            return state;
        }
    }
}
